<template>
  <b-form class="pb-5">
    <b-row>
      <b-col>
        <h1>{{ $t("livestream") }} {{ livestream.id }}</h1>
        <b-form-checkbox
          v-model="livestream.active"
          name="active"
          class="content-switch"
          switch
          ><b>{{ $t("active") }}</b></b-form-checkbox
        >
      </b-col>
      <b-col cols="3" align-self="center">
        <b-alert
          align-v="center"
          variant="warning"
          :show="saveMessage !== ''"
          >{{ this.saveMessage }}</b-alert
        >
      </b-col>
      <b-col cols="auto">
        <b-button
          :disabled="saveDisabled"
          class="mb-5 save-button"
          @click="save()"
        >
          {{ $t("save") }}
        </b-button>
      </b-col>
    </b-row>
    <b-form-group label="Link slug">
      <b-input-group :prepend="`${baseUrl}/livestream?id=`">
        <b-form-input v-model="livestream.id" />
      </b-input-group>
    </b-form-group>
    <a :href="`${baseUrl}/livestream?id=${livestream.id}`">{{
      `${baseUrl}/livestream?id=${livestream.id}`
    }}</a>
    <b-tabs>
      <b-tab :title="$t('livestream')">
        <LivestreamVideoDetail v-if="livestream" :livestream="livestream" />
      </b-tab>
      <b-tab :title="$t('chat')">
        <ChatDetail :chat="livestream.chat" />
      </b-tab>
      <b-tab :title="$t('speakers')">
        <SpeakerDetail
          :livestreams="livestreams"
          :speakers="livestream.speakers"
          :livestreamIndex="index"
        />
      </b-tab>
      <b-tab :title="$t('content')">
        <ContentConfig :object="livestream.blocks" />
      </b-tab>
      <b-tab title="Schedule">
        <h2 class="mt-3">Schedule</h2>
        <b-form-checkbox
          v-model="livestream.schedule.active"
          name="active"
          class="content-switch mt-3 mb-3"
          switch
          ><b>{{ $t("active") }}</b></b-form-checkbox
        >
        <b-form-group label="Select schedule">
          <model-list-select
            v-model="livestream.schedule.selected"
            :list="schedules"
            option-text="title"
            option-value="title"
            placeholder="Choose schedule"
          ></model-list-select>
        </b-form-group>
      </b-tab>
    </b-tabs>
  </b-form>
</template>
<script>
import ContentConfig from "@/components/admin/home/ContentConfig.vue";
import LivestreamVideoDetail from "@/components/admin/livestreams/LivestreamVideoDetail.vue";
import ChatDetail from "@/components/admin/livestreams/ChatDetail.vue";
import SpeakerDetail from "@/components/admin/livestreams/SpeakerDetail.vue";
import { ModelListSelect } from "vue-search-select";

export default {
  components: {
    ContentConfig,
    LivestreamVideoDetail,
    ChatDetail,
    SpeakerDetail,
    ModelListSelect,
  },
  data() {
    return {
      livestream: "",
      saveDisabled: true,
      saveMessage: "",
      schedules: [],
      index: "",
    };
  },
  async created() {
    this.isLoading = true;
    this.index = this.$route.query.id;
    await this.$store.dispatch("Livestreams/fetchAll");
    if (this.$route.query.id) {
      this.livestream = this.livestreams[this.index];
    } else {
      this.$store.commit("Livestreams/EMPTY_CURRENT");
      this.livestream = this.$store.state.Livestreams.livestream;
    }
    await this.fetchSchedules();
    this.schedules = this.$store.getters["Schedule/all"];
    this.saveDisabled = true;
    this.saveMessage = "";
    this.isLoading = false;
  },
  watch: {
    livestream: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  methods: {
    async doFetch() {
      this.isLoading = true;
      await this.$store.dispatch("Livestreams/fetchById", this.$route.query.id);
      this.isLoading = false;
    },
    async save() {
      this.isLoading = true;
      if (this.$route.query.id) {
        this.livestreams[this.index] = this.livestream;
      } else {
        this.livestreams.push(this.livestream);
      }
      await this.$store.dispatch("Livestreams/update", this.livestreams);
      this.$root.$bvToast.toast("Page settings saved!", { variant: "success" });
      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    },
    async fetchSchedules() {
      await this.$store.dispatch("Schedule/fetchAll");
    },
  },
  computed: {
    livestreamIndex() {
      return this.livestreams.findIndex(
        (livestream) => livestream.id == this.livestream.id
      ).id;
    },
    livestreams() {
      return this.$store.state.Livestreams.livestreams;
    },
    languages() {
      return this.$store.state.Languages.all;
    },
    baseUrl() {
      return process.env.VUE_APP_BASE_URL;
    },
  },
};
</script>
<style lang="scss">
$color-primary: var(--primary);
$color-secondary: var(--secondary);
$text-color-header: var(--theme-color-text_color_header);
$text-color-title: var(--theme-color-text_color_title);
$text-color-body: var(--theme-color-text_color_body);
$font-family: var(--theme-font-family);
$background-image: var(--theme-background-image);

.action-icon:hover {
  color: $color-secondary;
  cursor: pointer;
}
</style>
